import { Component, inject, input, output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IAsset } from 'src/app/core/interfaces/asset.interface'
import { EventService } from 'src/app/core/services/event.service'
import { CurrencyCode } from 'src/app/core/interfaces/currency.interface'
import { LegacyImageProcessorTypes } from 'src/app/core/interfaces/image.interface'
import { LegacyImageProcessorDirective } from 'src/app/core/directives/legacy-image-processor.directive'
import { AssetPricePipe } from 'src/app/core/pipes/asset-price.pipe'
import { IconButtonModule } from '../icon-button/icon-button.module'

@Component({
  selector: 'app-comparison-card',
  template: `
    @if (asset(); as asset) {
      <div class="card">
        @if (asset.images && asset.images.length) {
          <img [legacyImageProcessor]="{
              guid: asset.images[0],
              transparent: false,
              type: LegacyImageServiceTypes.CarouselItem,
            }"
            [alt]="asset.year + ' ' + asset.make + ' ' + asset.model"
            data-testid="image" />
        } @else {
          <img [src]="defaultImage()" alt="No photo available" data-testid="image" />
        }
        <div class="info">
          <span class="name">
            {{asset.year}} {{asset.make}} {{asset.model}}
          </span>
          <span class="price">
            @if (asset.list_price && !asset.is_list_price_hidden) {
              {{asset | assetPrice : currencyCode()}}
            } @else {
              <span i18n="@@detailsInformation_PriceNotAvailableText">Contact for Pricing</span>
            }
          </span>
        </div>
        <app-icon-button
          class="close"
          icon-btn-outlined
          icon-btn-small
          icon-btn-icon-xsmall
          matIcon="close"
          (click)="clearClicked.emit()"
          aria-label="Remove"
          i18n-aria-label="@@general_RemoveButton"
        ></app-icon-button>
      </div>
    }
  `,
  styles: [`
    @use "sass:map";
    @import "src/assets/_colors";
    @import "src/assets/typography";

    :host {
      width: 80px;
      max-width: 80px;
      background-color: map-get($light, ref, white);
      display: block;

      @include mq(large) {
        width: 100%;
        max-width: 324px;
      }
    }

    .card {
      display: flex;
      position: relative;
      height: 60px;
      width: 100%;

      img {
        flex-shrink: 0;
        width: 80px;
        height: 60px;
        border-radius: 4px;
      }

      .info {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 8px;
        display: none;
        overflow: hidden;
        flex-direction: column;
        align-items: flex-start;

        .name {
          margin-left: 12px;
          width: 75%;
          color: map-get($light, text, primary);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @include typography(body-2);
        }

        .price {
          margin-left: 12px;
          color: var(--theme-price-color);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          @include typography(body-2);
        }

        @include mq(large) {
          display: flex;
        }
      }

      .close {
        position: absolute;
        bottom: -40px;
        transform: translate(-50%, -50%);
        left: 50%;
        @include mq(large) {
          bottom: unset;
          top: 10px;
          left: unset;
          right: -15px;
        }
      }
    }
  `],
  imports: [
    CommonModule,
    LegacyImageProcessorDirective,
    AssetPricePipe,
    IconButtonModule,
  ],
})
export class ComparisonCardComponent {
  private _eventService = inject(EventService)

  asset = input<IAsset>()
  currencyCode = input<CurrencyCode>(CurrencyCode.USD)
  defaultImage = input<string>('')

  clearClicked = output<void>()

  LegacyImageServiceTypes = LegacyImageProcessorTypes
}
